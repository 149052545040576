import styled from "styled-components";
import { Box, IconButton } from "@material-ui/core";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Header = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 25px;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

export const Title = styled.h2`
  margin: 0;
`;

export const Subtitle = styled.h3`
  margin: 0;
`;

export const CloseButtonIcon = styled(IconButton)`
  padding-right: 0;

  &.MuiIconButton-root {
    padding-right: 0;
  }
`;
