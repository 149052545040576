export function toBrazilianDate(date: Date) {
  if (!date) {
    return '';
  }

  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  const hh = date.getHours();
  const MM = date.getMinutes();

  const formattedDate = `${(dd < 10) ? '0' + dd : dd}/${(mm < 10) ? '0' + mm : mm}/${yyyy}`;
  const formattedTime = `${(hh < 10) ? '0' + hh : hh}:${(MM < 10) ? '0' + MM : MM}`;
  
  return `${formattedDate} ${formattedTime}`;
}

export function getTodayDate(): string {
  const today = new Date();
  
  today.setHours(12, 0);

  return today.toISOString().split('T')[0];
}

export function fromSqlDateToDayMonthFormat(sqlDate: string) {
  if (!sqlDate) {
    return '';
  }

  const [_, month, day] = sqlDate.split('-');

  return `${forceZero(parseInt(day))}/${forceZero(parseInt(month))}`;
}

export function forceZero(number: number) {
  if (number < 10) {
    return '0' + number;
  }

  return number;
}

export function calculateAge(birthday: Date) {
  const today = new Date();
  // Calculate the initial age difference
  let age = today.getFullYear() - birthday.getFullYear();
  
  // Adjust the age if the birthday hasn't happened yet this year
  const monthDifference = today.getMonth() - birthday.getMonth();
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  
  return age;
}
