const Theme = {
    primary: '#0CB17B',
    secondary: '#001D4B',
    secondaryContrast: '#fff',
    secondaryAccent: '#00307C',
    text: '#333',
    info: '#0D6EFD',
    success: '#0fab7a',
    danger: '#db594f',
    warning: '#FD7E14',
    purple: '#6f42c1',
    light: '#dddddd',
    
    colors: {
        primary: {
            x1: '#0fab7a',
            primaryContrast: '#EEEEEE',
            rgb: '15,171,122'
        },
        info: '#0D6EFD',
        success: '#0fab7a',
        danger: '#db594f',
        warning: '#FD7E14',
        dangerRgb: '219,89,79',
        cold: '#49a9ff',
        light: '#dddddd',
        day: {
            x1: '#EEEEEE',
            x2: 'rgba(255,255,255,0.5)',
            x3: 'rgba(255,255,255,0.7)',
        },
        night: {
            x1: '#222831',
            x2: '#aaa',
            x3: '#555',
            x5: 'rgba(15,171,122,0.7)'
        },
        feedback: {
            success: {
                x1: '#34a853'
            },
            error: {
                x1: '#db594f'
            }
        }

    }
}

export default Theme;
